import React from "react"
// import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import Helmet from "react-helmet"

import Footer from "../components/footer"
import Imprint from "../components/imprint"
const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query SiteTitleQueryImprint {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div>
      <Helmet
        // htmlAttributes={}
        title={"Imprint"}
        titleTemplate={`%s | ${data.site.siteMetadata.title}`}
      >
        <meta name="robots" content="noindex" />
      </Helmet>
      <Imprint />
      <Footer />
    </div>
  )
}

export default IndexPage
