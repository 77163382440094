// import { Link } from "gatsby"
import React from "react"


const Imprint = () => {
  return (
    <div className="content">
      <div className="content__back">
        <div className="container">
          <a href="/" title="Back to home">
            Back to home
          </a>
        </div>
      </div>
      <div className="container">
        <div className="content__wrapper">
          <h1>Imprint</h1>
          <h5>Adresse</h5>
          <p>
            NEU_FUNDLAND Projekte GmbH
            <br />
            Heiligengeistwall 11
            <br />
            26122 Oldenburg
            <br />
            Telefon: 04412335004-2
            <br />
            E-Mail: <a href="mailto:hello@hiive.de">hello@hiive.de</a>
          </p>
          <h5>Vertretungsberechtigte Gesellschafter</h5>
          <p>Konrad Lüders, Alexis Angelis</p>
          <h5>Inhaltlich Verantwortlich</h5>
          <p>NEU_FUNDLAND Projekte GmbH</p>
          <h5>Redaktion</h5>
          <p>NEU_FUNDLAND Projekte GmbH</p>
          <h5>Gestaltung</h5>
          <p><a href="https://www.akre.studio">www.akre.studio</a></p>
          <h5>Bildnachweise</h5>
          <p><a href="https://unsplash.com">unsplash.com</a></p>
          <h5>Haftungsausschluss</h5>
          <p>
            Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte
            auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach
            §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
            verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
            überwachen oder nach Umständen zu forschen, die auf eine
            rechtswidrige Tätigkeit hinweisen.
          </p>
          Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
          Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
          Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
          Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden
          von entsprechenden
          <p>Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.</p>
          <p>
            Unser Angebot enthält Links zu externen Websites Dritter, auf deren
            Inhalte wir keinen Einfluss haben. Deshalb können wir für diese
            fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
            verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber
            der Seiten verantwortlich. Die verlinkten Seiten wurden zum
            Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
            Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
            erkennbar.
          </p>
          <p>
            Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist
            jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht
            zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir
            derartige Links umgehend entfernen.
          </p>
          <h5>Urheberrecht</h5>
          <p>
            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
            diesen Seiten unterliegen dem deutschen Urheberrecht. Die
            Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
            Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
            schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
            Downloads und Kopien dieser Seite sind nur für den privaten, nicht
            kommerziellen Gebrauch gestattet.
          </p>
          <p>
            Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt
            wurden, werden die Urheberrechte Dritter beachtet. Insbesondere
            werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie
            trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten
            wir um einen entsprechenden Hinweis. Bei Bekanntwerden von
            Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
            Quelle: eRecht24
          </p>
        </div>
      </div>
    </div>
  )
}

export default Imprint
